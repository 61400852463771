export function useAsset(path: string): string {
  // Check if the path is already a URL
  if (!path || path.startsWith('data:') || path.startsWith('blob:')) {
    return path
  }

  // Add WebP transformation parameters to Storyblok image URLs
  if (path.includes('a.storyblok.com')) {
    return path.includes('?')
      ? `${path}&format=webp`
      : `${path}?format=webp`
  }

  return path
} 