<template>
  <Disclosure
    v-editable="faq"
    as="div"
    class="pt-2 lg:pt-6"
    v-slot="{ open: disclosureOpen }"
    :default-open="faq.open"
  >
    <div
      class="text-lg bg-white rounded-2xl"
      :class="disclosureOpen ? 'border border-grey-2 bg-white' : 'border border-light-blue'"
    >
      <DisclosureButton
        class="flex px-6 py-[22px] w-full items-start justify-between text-left"
        :class="disclosureOpen ? 'bg-white rounded-t-2xl' : 'bg-light-blue rounded-2xl'"
      >
        <h3
          class="font-bold text-gray-900 text-sm md:text-xl"
        >
          {{ faq.question }}
        </h3>
        <span class="ml-6 flex h-7 items-center">
          <CommonSVGTemplate
            width="24"
            height="24"
            alt="Arrow Down"
            :path="arrowDown"
            :class="[
              disclosureOpen ? '-rotate-180' : 'rotate-0',
              'h-6 w-6 transform',
            ]"
          />
        </span>
      </DisclosureButton>

      <DisclosurePanel
        as="div"
        class="px-6 pt-[12px] pb-[22px]"
        :static="isServerSide()"
      >
        <div class="prose prose-lg max-w-none">
          <template v-if="faq.answer.content">
            <Vue3RuntimeTemplate :template="answer" />
          </template>
          <template v-else>
            <div v-html="faq.answer" />
          </template>
        </div>
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>

<script setup>
import Vue3RuntimeTemplate from "vue3-runtime-template";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
import { useAsset } from '~/composables/useAsset'
import { useStoryblokLink } from '~/composables/useStoryblokLink'

const props = defineProps({ faq: Object });

const arrowDown = `
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z" fill="#5F14E1"/>
`;

function isServerSide() {
  if (!process.client) {
    return true;
  }
  return false;
}

const { handleStoryblokLink } = useStoryblokLink()

const answer = computed(() =>
  renderRichText(props.faq.answer, {
    resolver: (component, blok) => {
      if (component === 'image' && blok.filename) {
        blok.filename = useAsset(blok.filename)
      }
      if (component === 'link' && blok.href) {
        blok = handleStoryblokLink(blok)
      }
      return `<component :blok='${JSON.stringify(
        blok
      )}' is="${component}" />`;
    },
  })
);
</script>
